'use client'
import React from 'react'
import Link from 'next/link'
import {buttonVariants} from '@/registry/default/ui/button'
import {Icons} from '@/components/icons'
import {BorderBeam} from '@/components/border-beam'
import {cn} from '@/lib/utils'
import {useSession} from 'next-auth/react'
import {useTranslations} from 'next-intl'

interface RetroSectionProps {
    config?: any;
}

export function RetroSection({config}: RetroSectionProps) {
    const t = useTranslations('home.retro')
    const {status} = useSession()
    return (
        <div className='container md:py-20 2xl:py-40 flex flex-col justify-center items-center md:gap-12 2xl:gap-16'>
            <h1 className='z-10 mt-20 text-center text-secondary dark:text-foreground/70 md:text-7xl 2xl:text-8xl font-bold '>
                {config?.slogan ?? t('slogan')}
            </h1>
            <h2
                className='bg-gradient-to-r text-center from-muted-foreground via-primary to-foreground  text-transparent bg-clip-text border-b pb-2 text-3xl 2xl:text-4xl font-semibold tracking-tight transition-colors first:mt-0'>{config?.desc ?? t('desc')}</h2>
            <div className='flex mt-4 items-center justify-center w-full space-x-4'>

                <Link
                    href={status === 'authenticated' ? `${config?.workspaceId}/dashboard/copilot` : `/${config.workspaceId}/login`}
                    className={cn(buttonVariants({size: 'lg'}), 'h-14 text-md font-medium rounded-full')}>
                    <Icons.tailwind className='mr-2 h-5 w-5'/>
                    {t('courseRecording')}
                </Link>

                <Link
                    href={`${config?.workspaceId}/resource`}
                    className={cn(buttonVariants({
                        size: 'lg',
                        variant: 'secondary',
                    }), 'border h-14 text-md font-medium rounded-full border-foreground')}>
                    <Icons.aria className='mr-2 h-4 w-4'/>
                    {t('teacherEntrance')}
                </Link>
            </div>
            <div className='mt-12 relative container'>
                <BorderBeam size={350} duration={24} delay={9}/>
            </div>
        </div>
    )
}